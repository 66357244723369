import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n'
import enLocale  from 'element-ui/lib/locale/lang/en'
import zhLocale  from 'element-ui/lib/locale/lang/zh-CN'
import config from './utils/config';

Vue.use(ElementUI);
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh', // 语言标识
  messages: {
    'zh': {
      ...require('@/lang/zh'),
      ...zhLocale
    }, // 中文语言包
    'en': {
      ...require('@/lang/en'),
      ...enLocale
    } // 英文语言包
  }
})

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.prototype.$config = config

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
