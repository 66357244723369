import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPc: ''
  },
  mutations: {
    setIsPc(state, arg) {
      state.isPc = arg;
    }
  },
  getters: {
    isPc: state =>{
      return state.isPc
    }
  },
  actions: {
    setIsPcAction({ commit, state }, arg) {
      commit('setIsPc', arg)
    }
  },
  modules: {
  }
})
