import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'muyou',
    component: () => import('../views/main/index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('../views/Product.vue')
      },
      {
        path: '/newsList',
        name: 'NewsList',
        component: () => import('../views/NewsList.vue'),
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../views/News.vue')
      },
      {
        path: '/hire',
        name: 'Hire',
        component: () => import('../views/Hire.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
