module.exports = {
	nav: {
		homePage: 'Home Page',
		aboutUs: 'About Us',
		product: 'Products',
		compantNews: 'Company News',
		hire: 'E-Recruit',
		contact: 'Contact Us'
	},
	productempty: 'Please Wait',
	title: {
		recruit: 'E-Recruit',
		news: 'NEWS',
		about: 'MUU GAMES',
		product: 'Products',
		cert: 'Business License'
	},
	navTitle: {
		aboutUs: 'MUU GAMES',
		product: 'Products',
		compantNews: 'Company News',
		hire: 'E-Recruit',
		contact: 'Contact Us'
	}
}
