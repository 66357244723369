module.exports = {
	nav: {
		homePage: '首页',
		aboutUs: '关于沐游',
		product: '产品介绍',
		compantNews: '公司新闻',
		hire: '人才招聘',
		contact: '联系我们'
	},
	productempty: '敬请期待',
	title: {
		recruit: '人才招聘',
		news: '最新动态',
		about: '关于沐游',
		product: '产品介绍',
		cert: '营业执照'
	},
	navTitle: {
		aboutUs: '关于沐游',
		product: '产品介绍',
		compantNews: '公司新闻',
		hire: '人才招聘',
		contact: '联系我们'
	}
}
